import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import TypeIt from 'typeit-react';
import { motion } from 'framer-motion';

import Seo from '../components/Seo';

import '../styles/global.css';

const Index = () => {
   return (
      <main>
         <Seo title='Home' />
         <div className='relative top-0 mt-8 md:mt-0 md:top-32 left-1/2 transform -translate-x-1/2 flex flex-col items-center z-50 mx-auto'>
            <StaticImage
               src='../images/Hunter-Environmental-Logo-No-Text.png'
               width={150}
               className='w-auto h-auto'
               alt='Hunter Environmental Logo'
            />
            <TypeIt
               className='hidden md:block pt-24 text-5xl tracking-loose font-medium max-w-xl text-white text-center z-50'
               getBeforeInit={(instance) => {
                  instance
                     .type('Your solution to all ')
                     .break()
                     .type('things Snow')
                     .pause(750)
                     .delete(4)
                     .pause(750)
                     .type('Ice')
                     .pause(750)
                     .delete(3)
                     .pause(750)
                     .type('Septic')
                     .pause(750)
                     .delete(6)
                     .pause(750)
                     .type('Drainage')
                     .pause(750)
                     .delete(8)
                     .pause(750)
                     .type('Demolition')
                     .pause(1500)
                     .delete(41)
                     .pause(500)
                     .type('We are')
                     .break()
                     .type('Hunter Environmental');
                  return instance;
               }}
            />
         </div>
         <div className='grid leftSplit absolute group w-full md:w-1/2 h-1/2 md:h-full top-0 md:left-0'>
            <StaticImage
               layout='fullWidth'
               style={{ gridArea: '1/1' }}
               alt='Hunter Environmental Front End Loader moving snow'
               src='../images/Hunter-Environmental-Snow-BG.jpg'
               formats={['auto', 'webp', 'avif']}
               className='inset-0 h-full z-20'
            />
            <div
               style={{ gridArea: '1/1' }}
               className='z-30 bg-snow-dark group-hover:opacity-0 transition duration-500 ease-in-out opacity-40 absolute inset-0'
            ></div>
            <motion.div
               style={{ gridArea: '1/1' }}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 1 }}
               className='z-50 absolute transform translate-x-1/2 right-1/2 md:-translate-x-0 md:mr-16 md:right-0 top-2/3 md:top-3/4'
            >
               <Link
                  to='/snow'
                  style={{ fontFamily: 'Muli' }}
                  className='shadow-lg bg-snow-dark hover:bg-snow transition ease-in-out duration-300 text-sm md:text-base rounded-md text-white px-12 py-2'
               >
                  Snow
               </Link>
            </motion.div>
         </div>
         <div className='grid rightSplit absolute group w-full md:w-1/2 h-1/2 md:h-full bottom-0 md:right-0'>
            <StaticImage
               layout='fullWidth'
               style={{ gridArea: '1/1' }}
               alt='Hunter Environmental Excavator digging foundation'
               src='../images/Hunter-Environmental-Dirt-Alt.jpg'
               formats={['auto', 'webp', 'avif']}
               className='inset-0 h-full z-20'
            />
            <div
               style={{ gridArea: '1/1' }}
               className='z-40 bg-dirt-dark transition duration-500 ease-in-out group-hover:opacity-0 opacity-30 absolute w-full h-full '
            ></div>
            <motion.div
               style={{ gridArea: '1/1' }}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: 1 }}
               className='z-50 absolute transform -translate-x-1/2 left-1/2 md:-translate-x-0 md:left-0 md:ml-16 top-3/4 md:top-3/4'
            >
               <Link
                  to='/dirt'
                  style={{ fontFamily: 'Muli' }}
                  className='shadow-lg bg-dirt-dark hover:bg-dirt transition ease-in-out duration-300  text-sm md:text-base rounded-md text-white px-12 py-2'
               >
                  Dirt
               </Link>
            </motion.div>
         </div>
         <footer className='absolute bottom-2 mb-4 px-4 h-4 w-full z-40 flex justify-between'>
            <Link to='/'>
               <StaticImage src='../images/Hunter-Environmental-Phone.png' width={150} alt='Hunter Environmental Phone Number 617-PLOW-PRO' />
            </Link>
            <div>
               <a className='inline' href='https://www.instagram.com/we.are.he/'>
                  <svg
                     className='w-6 h-6 fill-current text-white hover:text-black inline transition ease-in-out duration-300'
                     role='img'
                     viewBox='0 0 24 24'
                     xmlns='http://www.w3.org/2000/svg'
                  >
                     <title>Instagram</title>
                     <path d='M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z' />
                  </svg>
               </a>
               <a href='https://www.facebook.com/HunterEnvironmental'>
                  <svg
                     className='w-6 h-6 fill-current text-white hover:text-black  inline mx-4 transition ease-in-out duration-300'
                     role='img'
                     viewBox='0 0 24 24'
                     xmlns='http://www.w3.org/2000/svg'
                  >
                     <title>Facebook</title>
                     <path d='M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z' />
                  </svg>
               </a>
            </div>
         </footer>
      </main>
   );
};

export default Index;
